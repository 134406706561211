import React, { Component } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Shape from "../Images/Shape.png";
import Checkcircle from "../Images/checkcircle.png";
import apiService from "./../../service/apiService";
import { Link } from "react-router-dom";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMonthly: true,
      monthlyData: [],
      yearlyData: [],
    };
  }
  componentDidMount() {
    this.fetchMonthlyPrice();
    this.fetchYearlyPrice();
  }
  fetchMonthlyPrice = async () => {
    try {
      const response = await apiService.get("front/pricing/monthly");

      if (response.data && Array.isArray(response.data)) {
        this.setState({
          monthlyData: response.data,
        });
      } else {
      }
    } catch (error) {
    }
  };

  fetchYearlyPrice = async () => {
    try {
      const response = await apiService.get("front/pricing/yearly");

      if (response.data && Array.isArray(response.data)) {
        this.setState({
          yearlyData: response.data,
        });
      } else {
      }
    } catch (error) {
    }
  };
  render() {
    const { isMonthly } = this.state;
    return (
      <div>
        <Header />

        <div className="Pricing-Sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12" align="center">
                <div className="secHeading">
                  <h3>Collably Pricing</h3>
                  <p>No contracts. No surprise fees.</p>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="tavSec">
                  <div className="pillWid">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${isMonthly ? "active" : ""}`}
                          data-bs-toggle="pill"
                          // href="#home"
                          onClick={() => this.setState({ isMonthly: true })}
                        >
                          MONTHLY
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${!isMonthly ? "active" : ""}`}
                          data-bs-toggle="pill"
                          // href="#menu1"
                          onClick={() => this.setState({ isMonthly: false })}
                        >
                          YEARLY
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content">
                    <div
                      className={`tab-pane container ${isMonthly ? "active" : "fade"
                        }`}
                      id="home"
                    >
                      <div className="row pricingMainBGD">

                        {this.state.monthlyData && this.state.monthlyData.map((monData,i) => (
                          <React.Fragment key={monData.id}>
                            <div className="col-md-3 col-sm-6 col-12 padleftright0">
                            <div className={`pricingMainD ${i==2?"active":null}`}>
                              {i==2 && <span>MOST POPULAR</span>}
                              <div className="clearfix"></div>
                                <h3>$
                                  {monData.price} < small > /month</small >
                                </h3>
                                <h4>{monData.package}</h4>
                                <p>
                                  {monData.short_description}
                                </p>
                                <ul>
                                  {/* {monData.pakage_details.map((dataPackage,index)=>{
                                    <React.Fragment key={index}>
                                      <img src={Shape} />{dataPackage} */}
                                  {monData.pakage_details[0] && <li>
                                    <img src={Shape} />{monData.pakage_details[0]}
                                  </li>}
                                  {monData.pakage_details[1] && <li>
                                    <img src={Shape} />{monData.pakage_details[1]}
                                  </li>}
                                  {monData.pakage_details[2] && <li>
                                    <img src={Shape} />{monData.pakage_details[2]}
                                  </li>}
                                  {monData.pakage_details[3] && <li>
                                    <img src={Shape} />{monData.pakage_details[3]}
                                  </li>}
                                  {monData.pakage_details[4] && <li>
                                    <img src={Shape} />{monData.pakage_details[4]}
                                  </li>}
                                  {monData.pakage_details[5] && <li>
                                    <img src={Shape} />{monData.pakage_details[5]}
                                  </li>}
                                  {monData.pakage_details[6] && <li>
                                    <img src={Shape} />{monData.pakage_details[6]}
                                  </li>}
                                  {monData.pakage_details[7] && <li>
                                    <img src={Shape} />{monData.pakage_details[7]}
                                  </li>}
                                  {/* </React.Fragment>
                                    })} */}
                                  {/* <li>
                                    <img src={Shape} /> Website
                                  </li>
                                  <li>
                                    <img src={Shape} /> Description
                                  </li>
                                  <li>
                                    <img src={Shape} /> Projects Keywords
                                  </li>
                                  <li>
                                    <img src={Shape} /> Email ID
                                  </li>
                                  <li>
                                    <img src={Shape} /> Contact Person 1 & 2
                                  </li> */}
                                </ul>

                                <Link to="/contacts" className="btn btn-pricing">
                                  Choose plan
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}

                      </div>
                    </div>

                    <div
                      className={`tab-pane container ${!isMonthly ? "active" : "fade"
                        }`}
                      id="menu1"
                    >
                      <div className="row pricingMainBGD">
                        {this.state.yearlyData && this.state.yearlyData.map((yearData,i) => (
                          <React.Fragment key={yearData.id}>
                            <div className="col-md-3 col-sm-6 col-12 padleftright0">
                              <div className={`pricingMainD ${i==2?"active":null}`}>
                              {i==2 && <span>MOST POPULAR</span>}
                              <div className="clearfix"></div>
                                <h3>$
                                  {yearData.price}<small> /Year</small>
                                </h3>
                                <h4>{yearData.package}</h4>
                                <p>
                                  {yearData.short_description}
                                </p>
                                <ul>
                                  {yearData.pakage_details[0] && <li>
                                    <img src={Shape} />{yearData.pakage_details[0]}
                                  </li>}
                                  {yearData.pakage_details[1] && <li>
                                    <img src={Shape} />{yearData.pakage_details[1]}
                                  </li>}
                                  {yearData.pakage_details[2] && <li>
                                    <img src={Shape} />{yearData.pakage_details[2]}
                                  </li>}
                                  {yearData.pakage_details[3] && <li>
                                    <img src={Shape} />{yearData.pakage_details[3]}
                                  </li>}
                                  {yearData.pakage_details[4] && <li>
                                    <img src={Shape} />{yearData.pakage_details[4]}
                                  </li>}
                                  {yearData.pakage_details[5] && <li>
                                    <img src={Shape} />{yearData.pakage_details[5]}
                                  </li>}
                                  {yearData.pakage_details[6] && <li>
                                    <img src={Shape} />{yearData.pakage_details[6]}
                                  </li>}
                                  {yearData.pakage_details[7] && <li>
                                    <img src={Shape} />{yearData.pakage_details[7]}
                                  </li>}
                                </ul>

                                <Link to="/contacts" className="btn btn-pricing">
                                  Choose plan
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                        {/* <div className="col-md-3 col-sm-3 col-12 padleftright0">
                          <div className="pricingMainD">
                            <h3>
                              $500<small>/year</small>
                            </h3>
                            <h4>Bronze</h4>
                            <p>
                              For most businesses that want to otpimize web
                              queries
                            </p>
                            <ul>
                              <li>
                                <img src={Shape} /> Project Name
                              </li>
                              <li>
                                <img src={Shape} /> Website
                              </li>
                              <li>
                                <img src={Shape} /> Description
                              </li>
                              <li>
                                <img src={Shape} /> Projects Keywords
                              </li>
                            </ul>

                            <a href="#" className="btn btn-pricing">
                              Choose plan
                            </a>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-12 padleftright0">
                          <div className="pricingMainD active">
                            <span>MOST POPULAR</span>
                            <h3>
                              $1000<small>/year</small>
                            </h3>
                            <h4>Silver</h4>
                            <p>
                              For most businesses that want to otpimize web
                              queries
                            </p>
                            <ul>
                              <li>
                                <img src={Checkcircle} /> Project Name
                              </li>
                              <li>
                                <img src={Checkcircle} /> Website
                              </li>
                              <li>
                                <img src={Checkcircle} /> Description
                              </li>
                              <li>
                                <img src={Checkcircle} /> Projects Keywords
                              </li>
                              <li>
                                <img src={Checkcircle} /> Email ID
                              </li>
                            </ul>

                            <a href="#" className="btn btn-pricing">
                              Choose plan
                            </a>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-12 padleftright0">
                          <div className="pricingMainD">
                            <h3>
                              $2000<small>/year</small>
                            </h3>
                            <h4>Gold</h4>
                            <p>
                              For most businesses that want to otpimize web
                              queries
                            </p>
                            <ul>
                              <li>
                                <img src={Shape} /> Project Name
                              </li>
                              <li>
                                <img src={Shape} /> Website
                              </li>
                              <li>
                                <img src={Shape} /> Description
                              </li>
                              <li>
                                <img src={Shape} /> Projects Keywords
                              </li>
                              <li>
                                <img src={Shape} /> Email ID
                              </li>
                              <li>
                                <img src={Shape} /> Contact Person 1 & 2
                              </li>
                            </ul>

                            <a href="#" className="btn btn-pricing">
                              Choose plan
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </div>
    );
  }
}

export default Pricing;
