import React, { Component } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import CountryDropdown from "../Components/Admin/CountryDropdown";
import apiService from "./../../service/apiService";
import ProjectDropdown from "../Components/Admin/ProjectDropdown";
import TargetKeywordDropdown from "../Components/Admin/TargetKeywordDropdown";
import Swal from "sweetalert2";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project_name: "",
      website: "",
      official_email_id: "",
      description: "",
      contact_name: "",
      contact_number: "",
      password: "",
      rpass: "",
      show_contact_details: "yes",
      whatsapp: "",
      // telegram: "",
      // twitter: "",
      isLoading: false,
      errorMessage: null,
      successMessageVisible: false,
      timeoutId: null,
      socialicon: "",
      country: "",
      project_keyword: "",
      target_project_keyword: "",
    };
  }


  componentWillUnmount() {
    // Clear the timeout when the component unmounts
    const { timeoutId } = this.state;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  // handleInputChange = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  // };

  handleInputChange = (e) => {
    if (e.target.name === "socialicon") {
      // Handle dropdown selection
      this.setState({
        socialicon: e.target.value,
      });

      setTimeout(() => {
        if (this.state.socialicon == "telegram") {
          this.setState({
            telegram: "t.me/",
          });
        }
        else {
          this.setState({
            twitter: "",
          });
        }
      }, 10);

    } else {
      // Handle other input changes
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleCountryChange = (selectedCountry) => {
    this.setState({ country: selectedCountry });
  };

  handleProjectKeywordChange = (values) => {
    const keywordsArr = values?.map((el) => el.value);
    const keywordsStr = keywordsArr.join(",");
    this.setState({ project_keyword: keywordsStr });
  };

  handleTargetKeywordChange = (values) => {
    this.setState({ target_project_keyword: values });

    const keywordsArr = values?.map((el) => el.value);
    const keywordsStr = keywordsArr.join(",");
    this.setState({ target_project_keyword: keywordsStr });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      description: this.state.description,
      project_name: this.state.project_name,
      website: this.state.website,
      official_email_id: this.state.official_email_id,
      country: this.state.country,
      project_keyword: this.state.project_keyword,
      target_project_keyword: this.state.target_project_keyword,
      contact_name: this.state.contact_name,
      contact_number: this.state.contact_number,
      password: this.state.password,
      rpass: this.state.rpass,
      whatsapp: this.state.whatsapp,
      // telegram: this.state.telegram,
      // twitter: this.state.twitter,
      telegram: this.state.socialicon === "telegram" ? this.state.telegram : "",
      twitter: this.state.socialicon === "twitter" ? this.state.twitter : "",

      show_contact_details: this.state.show_contact_details,
    };

    try {
      const response = await apiService.post(
        "front/users/register",
        null,
        formData
      );

      if (response.status === "200") {
        // Handle success, e.g., show a success message

        // Reset the form and show the success popup
        this.setState({
          description: "",
          project_name: "",
          website: "",
          official_email_id: "",
          // country: "",
          // project_keyword: "",
          // target_project_keyword: "",
          contact_name: "",
          contact_number: "",
          password: "",
          rpass: "",
          whatsapp: "",
          telegram: "",
          twitter: "",
          show_contact_details: "YES",
          isLoading: false,
          successMessageVisible: false,
        });

        Swal.fire({
          icon: 'success',
          title: 'Your data has been accepted.',
          showConfirmButton: false,
          timer: 2500
        });


        // Clear the previous timeout if exists
        const { timeoutId } = this.state;
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }

    setTimeout(() => {
      this.setState({ successMessageVisible: false });
    }, 2000);
  };

  render() {
    return (
      <div>
        <Header />

        <div className="homeabout-Sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="secHeading">
                  <h3>Register with us</h3>
                </div>
              </div>
              {this.state.successMessageVisible && (
                <div className="alert alert-success" role="alert">
                  Successfully registered user
                </div>
              )}
              <div className="col-md-2 col-sm-2"></div>
              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className="contactFormD">
                  <form
                    className=""
                    action="#"
                    method="post"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="project_name">Project Name:</label>
                          <input
                            type="text"
                            name="project_name"
                            className="form-control"
                            id="project_name"
                            value={this.state.project_name}
                            onChange={this.handleInputChange}
                            placeholder="Enter Your Project Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="website">Website:</label>
                          <input
                            type="text"
                            name="website"
                            className="form-control"
                            id="website"
                            value={this.state.website}
                            onChange={this.handleInputChange}
                            placeholder="Website Link"
                            required
                          />
                        </div>
                      </div>

                      <CountryDropdown
                        onChangeCountryProp={this.handleCountryChange}
                        style={{ width: "100%" }}
                      />

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="official_email_id">Email ID:</label>
                          <input
                            type="email"
                            name="official_email_id"
                            className="form-control"
                            id="official_email_id"
                            placeholder="Official Email ID"
                            onChange={this.handleInputChange}
                            value={this.state.official_email_id}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="description">Description:</label>
                          <textarea
                            className="form-control"
                            name="description"
                            rows="3"
                            id="description"
                            placeholder="Description"
                            maxLength="300"
                            onChange={this.handleInputChange}
                            value={this.state.description}
                            required
                          ></textarea>
                        </div>
                      </div>

                      <ProjectDropdown
                        onChangeProjectKeywordProp={
                          this.handleProjectKeywordChange
                        }
                      />

                      <TargetKeywordDropdown
                        onChangeTargetKeywordProp={
                          this.handleTargetKeywordChange
                        }
                      />

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="contact_name">Conatct Person:</label>
                          <input
                            type="text"
                            name="contact_name"
                            className="form-control"
                            id="contact_name"
                            placeholder="Enter Conatct Person Name"
                            onChange={this.handleInputChange}
                            value={this.state.contact_name}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="socialicon">Contact Medium:</label>
                          <select
                            className="form-control"
                            id="socialicon"
                            name="socialicon"
                            value={this.state.socialicon}
                            onChange={this.handleInputChange}
                            required
                          >
                            <option value="">--Select--</option>
                            <option value="telegram">Telegram</option>
                            <option value="twitter">Twitter</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="socialId">
                            {this.state.socialicon === "telegram"
                              ? "Telegram"
                              : "Twitter"}{" "}
                            ID:
                          </label>
                          <input
                            type="id"
                            name={this.state.socialicon}
                            className="form-control"
                            id={this.state.socialicon}
                            placeholder={`Enter ${this.state.socialicon === "telegram"
                              ? "Telegram"
                              : "Twitter"
                              } ID`}
                            onChange={this.handleInputChange}
                            value={this.state[this.state.socialicon]}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="password">Create Password:</label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="password"
                            placeholder="Create Strong Password"
                            onChange={this.handleInputChange}
                            value={this.state.password}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="rpass">Re-enter Password:</label>
                          <input
                            type="password"
                            name="rpass"
                            className="form-control"
                            id="rpass"
                            placeholder="Confirm Password"
                            onChange={this.handleInputChange}
                            value={this.state.rpass}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="show_contact_details">
                            Do you want to show contact details?:
                          </label>
                        </div>
                        <label className="checkstyle">
                          Yes
                          <input
                            type="radio"
                            name="optradio"
                            checked={this.state.show_contact_details === "yes"}
                            onChange={() =>
                              this.setState({ show_contact_details: "yes" })
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="checkstyle">
                          No
                          <input
                            type="radio"
                            name="optradio"
                            checked={this.state.show_contact_details === "no"}
                            onChange={() =>
                              this.setState({ show_contact_details: "no" })
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <br />

                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <button type="submit" className="btn btn_gradiant">
                          Register{" "}
                          <i
                            className="fa fa-chevron-circle-right"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </div>
    );
  }
}

export default Register;
