import React, { Component } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import apiService from "./../../service/apiService";
import { Modal } from "react-bootstrap";
import CountryDropdown from "../Components/Admin/CountryDropdown";
import ProjectDropdown from "../Components/Admin/ProjectDropdown";
import TargetKeywordDropdown from "../Components/Admin/TargetKeywordDropdown";
import Swal from "sweetalert2";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project_name: "",
      passwordSuccessMessage: false,
      website: "",
      country: "",
      official_email_id: "",
      description: "",
      project_keyword: "",
      target_project_keyword: "",
      contact_name: "",
      contact_number: "",
      show_contact_details: "",
      whatsapp: "",
      telegram: "",
      twitter: "",
      p_id: "",
      newPassword: "",
      reenterPassword: "",
      passwordMismatch: false,
      isProfileTabEnabled: true,
      isSecurityTabEnabled: false,
      userInfo: null,
      logoutConfirmationVisibility: false,
      successMessage: false,
      oldPassword: "",
    };
  }

  componentDidMount() {
    const loggedInUserInfo = JSON.parse(
      localStorage.getItem("frontendUserInfo")
    );

    if (loggedInUserInfo) {
      this.setState({
        project_name: loggedInUserInfo.project_name,
        website: loggedInUserInfo.website,
        country: loggedInUserInfo.country,
        official_email_id: loggedInUserInfo.official_email_id,
        description: loggedInUserInfo.description,
        project_keyword: loggedInUserInfo.project_keyword,
        target_project_keyword: loggedInUserInfo.target_project_keyword,
        contact_name: loggedInUserInfo.contact_name,
        contact_number: loggedInUserInfo.contact_number,
        whatsapp: loggedInUserInfo.whatsapp,
        twitter: loggedInUserInfo.twitter,
        telegram: "t.me/" + loggedInUserInfo.telegram,
        show_contact_details: loggedInUserInfo.show_contact_details,
        p_id: loggedInUserInfo.p_id,
        userInfo: loggedInUserInfo,
      });
    }
  }

  onProjectChange = (e) => {
    this.setState({
      project_name: e.target.value,
    });
  };

  onWebsiteChange = (e) => {
    this.setState({
      website: e.target.value,
    });
  };

  onCountryChange = (e) => {
    this.setState({
      country: e,
    });
  };

  onEmailChange = (e) => {
    this.setState({
      official_email_id: e.target.value,
    });
  };

  onDescriptionChange = (e) => {
    this.setState({
      description: e.target.value,
    });
  };

  onProjectKeywordChange = (values) => {
    const keywordsArr = values?.map((el) => el.value);
    const keywordsStr = keywordsArr.join(",");
    this.setState({
      project_keyword: keywordsStr,
    });
  };

  onTargetChange = (values) => {
    const keywordsArr = values?.map((el) => el.value);
    const keywordsStr = keywordsArr.join(",");
    this.setState({
      target_project_keyword: keywordsStr,
    });
  };

  onContactnmChange = (e) => {
    this.setState({
      contact_name: e.target.value,
    });
  };

  oncontactnumberChange = (e) => {
    this.setState({
      contact_number: e.target.value,
    });
  };

  onWhatsappChange = (e) => {
    this.setState({
      whatsapp: e.target.value,
    });
  };

  onTwitterChange = (e) => {
    this.setState({
      twitter: e.target.value,
    });
  };

  onTelegramChange = (e) => {
    this.setState({
      telegram: e.target.value,
    });
  };

  onNewPasswordChange = (e) => {
    this.setState({
      newPassword: e.target.value,
      passwordMismatch: false, // Reset the passwordMismatch state when the new password changes
    });
  };
  oldPasswordChange = (e) => {
    this.setState({
      oldPassword: e.target.value,
    });
  };

  onReenterPasswordChange = (e) => {
    this.setState({
      reenterPassword: e.target.value,
      passwordMismatch: false, // Reset the passwordMismatch state when the re-entered password changes
    });
  };

  onSubmitPassword = async (e) => {
    e.preventDefault();
    const { newPassword, reenterPassword, oldPassword, p_id } = this.state;

    // Check if passwords match
    if (newPassword !== reenterPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Confirm password has not Matched!",
      });

    } else {
      // const formData={
      //   old_password:oldPassword,
      //   new_password:newPassword,
      // };
      const formData = new FormData();
      formData.append("old_password", oldPassword);
      formData.append("new_password", newPassword);
      formData.append("p_id", p_id);

      try {
        const response = await apiService.post(
          "front/users/update_password",
          formData
        );
        if (response.status == "200") {
          this.setState({
            newPassword: "",
            reenterPassword: "",
            oldPassword: "",
            passwordMismatch: false,
          });
          Swal.fire({
            icon: 'success',
            title: 'Password Changed Successfully.',
            showConfirmButton: false,
            timer: 3000
          });
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "You have entered wrong password!",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      }
    }
  };

  onUpdateUserprofile = async (e) => {
    e.preventDefault();
    //API Call
    try {
      const formData = {
        project_name: this.state.project_name,
        website: this.state.website,
        country: this.state.country,
        official_email_id: this.state.official_email_id,
        description: this.state.description,
        project_keyword: this.state.project_keyword,
        target_project_keyword: this.state.target_project_keyword,
        contact_name: this.state.contact_name,
        contact_number: this.state.contact_number,
        whatsapp: this.state.whatsapp,
        twitter: this.state.twitter,
        telegram: this.state.telegram,
        show_contact_details: this.state.show_contact_details,
        p_id: this.state.p_id,
      };
      const response = await apiService.post(
        "front/users/update",
        null,
        formData
      );

      if (response.status === "200") {
        localStorage.setItem("frontendUserInfo", JSON.stringify(response.data));

        Swal.fire({
          icon: 'success',
          title: 'Profile Updated Successfully.',
          showConfirmButton: false,
          timer: 3000
        });

        // this.setState({
        //   successMessage: "Profile Updated.",
        // });

        // setTimeout(() => {
        //   this.setState({
        //     successMessage: false,
        //   });
        // }, 3000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong! Please try again later.',
        });
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  };

  onClickProfileTab = () => {
    this.setState({
      isProfileTabEnabled: true,
      isSecurityTabEnabled: false,
    });
  };

  onClickSecurityTab = () => {
    this.setState({
      isProfileTabEnabled: false,
      isSecurityTabEnabled: true,
    });
  };

  showLogoutConfirmationModal = () => {
    this.setState({
      logoutConfirmationVisibility: true,
    });
  };

  hideLogoutConfirmationModal = () => {
    this.setState({
      logoutConfirmationVisibility: false,
    });
  };

  // Updated logout handler
  logoutHandler = () => {
    // Hide the logout confirmation modal
    this.hideLogoutConfirmationModal();

    // Perform logout action
    localStorage.removeItem("frontendUserInfo");
    window.location.reload();
  };

  render() {
    return (
      <div>
        <Header />

        <div className="homeabout-Sec">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="left-profilemainD">
                  <div className="left-profileD">
                    <img src={require("./../Images/image-8.png")} alt="image" />
                    <h4>
                      {this.state?.userInfo?.project_name}{" "}
                      <i
                        className="fa fa-chevron-circle-down"
                        aria-hidden="true"
                      ></i>
                    </h4>
                    <p>{this.state?.userInfo?.official_email_id}</p>
                    <div className="clearfix"></div>
                    <ul className="nav nav-pills flex-column">
                      <li className={`${this.state.isProfileTabEnabled ? "active" : ""}`}>
                        <a
                          data-toggle="pill"
                          href="javascript:void(0);"
                          onClick={this.onClickProfileTab}
                        >
                          <i className="fa fa-user-o" aria-hidden="true"></i>{" "}
                          &emsp; Profile{" "}
                          <i
                            className="fa fa-angle-right pull-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li className={`${!this.state.isProfileTabEnabled ? "active" : ""}`}>
                        <a
                          data-toggle="pill"
                          href="javascript:void(0);"
                          onClick={this.onClickSecurityTab}
                        >
                          <i className="fa fa-lock" aria-hidden="true"></i>{" "}
                          &emsp; Security{" "}
                          <i
                            className="fa fa-angle-right pull-right"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <hr />
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          onClick={this.showLogoutConfirmationModal}
                        >
                          <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-sm-8 col-xs-12">
                <div className="profileFormD">
                  <div className="tab-content">
                    {this.state.isProfileTabEnabled && (
                      <div id="home">
                        <div className="contactFormD">
                          {this.state.successMessage && (
                            <div
                              className="alert alert-success"
                              role="alert"
                            >
                              Profile updated!
                            </div>
                          )}
                          <form onSubmit={this.onUpdateUserprofile}>
                            <div className="row">
                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="project_name">
                                    Project Name:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="project_name"
                                    name="project_name"
                                    value={this.state.project_name}
                                    onChange={this.onProjectChange}
                                    placeholder="Enter Your Project Name"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="website">Website:</label>
                                  <input
                                    type="text"
                                    name="website"
                                    className="form-control"
                                    id="website"
                                    value={this.state.website}
                                    onChange={this.onWebsiteChange}
                                    placeholder="Website Link"
                                    required
                                  />
                                </div>
                              </div>

                              <CountryDropdown
                                selectedCountryProp={this.state.country}
                                onChangeCountryProp={this.onCountryChange}
                                style={{ width: "100%" }}
                              />

                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="official_email_id">
                                    Email ID:
                                  </label>
                                  <input
                                    type="email"
                                    name="official_email_id"
                                    className="form-control"
                                    id="official_email_id"
                                    placeholder="Official Email ID"
                                    value={this.state.official_email_id}
                                    onChange={this.onEmailChange}
                                    required
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="description">
                                    Description:
                                  </label>
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    rows="3"
                                    id="description"
                                    placeholder="Description"
                                    value={this.state.description}
                                    onChange={this.onDescriptionChange}
                                    required
                                  ></textarea>
                                </div>
                              </div>

                              {/* <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="project_keyword">
                                    Project Keyword:
                                  </label>
                                  <input
                                    type="text"
                                    name="project_keyword"
                                    className="form-control"
                                    id="project_keyword"
                                    placeholder="Your Project Keyword"
                                    onChange={this.onProjectKeywordChange}
                                    value={this.state.project_keyword}
                                    required
                                  />
                                </div>
                              </div> */}

                              <ProjectDropdown
                                selectedProjectKeywordProp={
                                  this.state.project_keyword
                                }
                                onChangeProjectKeywordProp={
                                  this.onProjectKeywordChange
                                }
                                style={{ width: "100%" }}
                              />
                              {/* <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="target_project_keyword">
                                    Target Keyword:
                                  </label>
                                  <input
                                    type="text"
                                    name="target_project_keyword"
                                    className="form-control"
                                    id="target_project_keyword"
                                    placeholder="Target Project Keyword"
                                    onChange={this.onTargetChange}
                                    value={this.state.target_project_keyword}
                                    required
                                  />
                                </div>
                              </div> */}

                              <TargetKeywordDropdown
                                selectedTargetKeywordProp={
                                  this.state.target_project_keyword
                                }
                                onChangeTargetKeywordProp={this.onTargetChange}
                                style={{ width: "100%" }}
                              />

                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="contact_name">
                                    Conatct Person:
                                  </label>
                                  <input
                                    type="text"
                                    name="contact_name"
                                    className="form-control"
                                    id="contact_name"
                                    placeholder="Enter Conatct Person Name"
                                    onChange={this.onContactnmChange}
                                    value={this.state.contact_name}
                                    required
                                  />
                                </div>
                              </div>

                              {/* <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="mob1">Conatct Number:</label>
                                  <input
                                    type="number"
                                    name="contact_number"
                                    className="form-control"
                                    id="contact_number"
                                    placeholder="Contact Number"
                                    onChange={this.oncontactnumberChange}
                                    value={this.state.contact_number}
                                    required
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="whatsapp">Whatsapp:</label>
                                  <input
                                    type="number"
                                    name="whatsapp"
                                    className="form-control"
                                    id="whatsapp"
                                    placeholder="Enter Whatsapp Number"
                                    onChange={this.onWhatsappChange}
                                    value={this.state.whatsapp}
                                  />
                                </div>
                              </div> */}

                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="telegram">Telegram:</label>
                                  <input
                                    type="id"
                                    name="telegram"
                                    className="form-control"
                                    id="telegram"
                                    placeholder="Enter Telegram ID"
                                    onChange={this.onTelegramChange}
                                    value={this.state.telegram}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="twitter">Twitter:</label>
                                  <input
                                    type="id"
                                    name="twitter"
                                    className="form-control"
                                    id="twitter"
                                    placeholder="Enter Twitter ID"
                                    onChange={this.onTwitterChange}
                                    value={this.state.twitter}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                  <label htmlFor="show_contact_details">
                                    Do you want to show contact details?:
                                  </label>
                                </div>
                                <label className="checkstyle">
                                  Yes
                                  <input
                                    type="radio"
                                    name="optradio"
                                    checked={
                                      this.state.show_contact_details === "yes"
                                    }
                                    onChange={() =>
                                      this.setState({
                                        show_contact_details: "yes",
                                      })
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <label className="checkstyle">
                                  No
                                  <input
                                    type="radio"
                                    name="optradio"
                                    checked={
                                      this.state.show_contact_details === "no"
                                    }
                                    onChange={() =>
                                      this.setState({
                                        show_contact_details: "no",
                                      })
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              <div
                                className="col-md-12 col-sm-12 col-xs-12"
                                align="right"
                              >
                                <button
                                  type="submit"
                                  className="btn btn_gradiant"
                                >
                                  Update{" "}
                                </button>
                                {/* <span className="btnbrdrGrad">
                                  <button
                                    type="button"
                                    className="btn btn_gradiant_brdr"
                                  >
                                    Cancel
                                  </button>
                                </span> */}
                                <br />
                              </div>

                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {this.state.isSecurityTabEnabled && (
                      <div id="menu1">
                        <div className="contactFormD">
                          <form className="" onSubmit={this.onSubmitPassword}>
                            {this.state.passwordSuccessMessage && <div
                              className="alert alert-success Add"
                            >
                              <strong>Password changed Successfully!</strong>
                            </div>}
                            <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                  <label for="opass">Old Password:</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="opass"
                                    placeholder="Enter Old Password"
                                    onChange={this.oldPasswordChange}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label for="npass">New Password:</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="npass"
                                    placeholder="Create Password"
                                    onChange={this.onNewPasswordChange}
                                    value={this.state.newPassword}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-group">
                                  <label for="rpass">Re-enter Password:</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="rpass"
                                    placeholder="Confirm Password"
                                    onChange={this.onReenterPasswordChange}
                                    value={this.state.reenterPassword}
                                    required
                                  />
                                </div>
                              </div>
                              <div
                                className="col-md-12 col-sm-12 col-xs-12"
                                align="right"
                              >
                                <button
                                  type="submit"
                                  className="btn btn_gradiant"
                                >
                                  Update
                                </button>
                                {/* <span className="btnbrdrGrad">
                                  <button
                                    type="button"
                                    className="btn btn_gradiant_brdr"
                                  >
                                    Cancel
                                  </button>
                                </span> */}
                              </div>
                              {this.state.passwordMismatch && (
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <p className="text-danger">
                                    Passwords do not match!
                                  </p>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal

          show={this.state.logoutConfirmationVisibility}
          onHide={this.hideLogoutConfirmationModal}
        >
          <Modal.Body align="center">
            <h4 className="modal-title mart30">Do you want to logout?</h4>

            <button
              type="submit"
              className="btn btn_gradiant"
              onClick={this.logoutHandler}
            >
              Yes{" "}
            </button>

            <span className="btnbrdrGrad">
              <button
                type="button"
                className="btn btn_gradiant_brdr"
                onClick={this.hideLogoutConfirmationModal}
              >
                No
              </button>
            </span>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    );
  }
}

export default Profile;
