import React, { Component } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProjectList from "../Components/ProjectList";
import apiService from "./../../service/apiService";
import Carousel from "react-material-ui-carousel";
import { FadeLoader } from "react-spinners";

class Project extends Component {
  state = {
    activeSlideIndex1: 0,
    activeSlideIndex2: 0,
    activeSlideIndex3: 0,
    firstSlider: [],
    secondSlider: [],
    thirdSlider: [],
    highlightActive: true,
    isLoading: false,
  };

  setActiveSlideIndex1 = (e) => {
    this.setState({
      activeSlideIndex1: e,
    });
  };
  setActiveSlideIndex2 = (e) => {
    this.setState({
      activeSlideIndex2: e,
    });
  };
  setActiveSlideIndex3 = (e) => {
    this.setState({
      activeSlideIndex3: e,
    });
  };

  componentDidMount() {
    this.fetchFirstSlider();
    this.fetchSecondSlider();
    this.fetchThirdSlider();
  }

  handleImageClick = (link) => {
    // Perform any logic before opening the link if needed

    // Check if the link is an absolute URL or a relative URL
    const isAbsoluteUrl =
      link.startsWith("http://") || link.startsWith("https://");

    // Open the link in a new tab
    if (isAbsoluteUrl) {
      window.open(link, "_blank");
    } else {
      // Prepend the link with the appropriate protocol (e.g., 'http://') for relative URLs
      window.open(`http://${link}`, "_blank");
    }
  };

  fetchFirstSlider = async () => {
    this.setState({
      isLoading: true,
    });
    try {
      const response = await apiService.get("front/first_slider/");

      if (response.data && Array.isArray(response.data)) {
        setTimeout(() => {
          this.setState({
            firstSlider: response.data,
            isLoading: false,
          });
        }, 700);
      } else {
      }
    } catch (error) {}
  };

  fetchSecondSlider = async () => {
    try {
      const response = await apiService.get("front/second_slider/");
      if (response.data && Array.isArray(response.data)) {
        setTimeout(() => {
          this.setState({
            secondSlider: response.data,
          });
        }, 700);
      } else {
      }
    } catch (error) {}
  };

  fetchThirdSlider = async () => {
    try {
      const response = await apiService.get("front/third_slider/");

      if (response.data && Array.isArray(response.data)) {
        setTimeout(() => {
          this.setState({
            thirdSlider: response.data,
          });
        }, 700);
      } else {
      }
    } catch (error) {}
  };

  handleHighlightToggle = () => {
    this.setState((prevState) => ({
      highlightActive: !prevState.highlightActive,
    }));
  };

  // setFirstSliderIndex = (e) => {
  //   this.setState({
  //     activeSlideIndex1: e,
  //   });
  // };

  //

  render() {
    const { highlightActive } = this.state;

    return (
      <>
        <Header />

        <div className="slider-Sec">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-8 col-8">
                <div className="slideHead">
                  <h2>Match Your Project With Potential Partners</h2>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-4">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="mySwitch"
                    name="darkmode"
                    value="yes"
                    onChange={this.handleHighlightToggle}
                    checked={highlightActive}
                    //checked
                  />
                  <label className="form-check-label" htmlFor="mySwitch">
                    Highlight
                  </label>
                </div>
              </div>

              <div className="col-md-12 col-sm-12 col-12">
                {this.state.isLoading && (
                  <FadeLoader
                    color="#f33984"
                    loading={this.state.isLoading}
                    size={250}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    className="adloader"
                  />
                )}
              </div>
              {/* First Slider */}
              {highlightActive && (
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="adSlider">
                    <Carousel
                      showArrows={true}
                      showThumbs={false}
                      autoPlay={true}
                      interval={6000}
                      infiniteLoop={true}
                      emulateTouch={true}
                    >
                      {this.state.firstSlider &&
                        this.state.firstSlider.map((muiCarousel) => (
                          <div
                            key={muiCarousel.id}
                            onClick={() =>
                              this.handleImageClick(muiCarousel.link)
                            }
                          >
                            <img
                              src={muiCarousel.image}
                              alt={muiCarousel.title}
                            />
                          </div>
                        ))}
                    </Carousel>
                  </div>
                </div>
              )}

              {/* Second Slider */}
              {highlightActive && (
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="adSlider">
                    <Carousel
                      showArrows={true}
                      showThumbs={false}
                      autoPlay={true}
                      interval={6000}
                      infiniteLoop={true}
                      emulateTouch={true}
                    >
                      {this.state.secondSlider &&
                        this.state.secondSlider.map((muiCarousel) => (
                          <div
                            key={muiCarousel.id}
                            onClick={() =>
                              this.handleImageClick(muiCarousel.link)
                            }
                          >
                            <img
                              src={muiCarousel.image}
                              alt={muiCarousel.title}
                            />
                          </div>
                        ))}
                    </Carousel>
                  </div>
                </div>
              )}

              {/* Third Slider */}
              {highlightActive && (
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="adSlider">
                    <Carousel
                      showArrows={true}
                      showThumbs={false}
                      autoPlay={true}
                      interval={6000}
                      infiniteLoop={true}
                      emulateTouch={true}
                    >
                      {this.state.thirdSlider &&
                        this.state.thirdSlider.map((muiCarousel) => (
                          <div
                            key={muiCarousel.id}
                            onClick={() =>
                              this.handleImageClick(muiCarousel.link)
                            }
                          >
                            <img
                              src={muiCarousel.image}
                              alt={muiCarousel.title}
                            />
                          </div>
                        ))}
                    </Carousel>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ProjectList />

        <Footer />
      </>
    );
  }
}

export default Project;
